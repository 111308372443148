var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: { open: _vm.open, title: _vm.$t("lang.enviar_imagen.msg") },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit($event)
            }
          }
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("lang.correos.msg")))]),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("EmailTagsInput", {
                attrs: {
                  "max-items": 10,
                  error: _vm.errors.firstOfArray("recipients")
                },
                model: {
                  value: _vm.recipients,
                  callback: function($$v) {
                    _vm.recipients = $$v
                  },
                  expression: "recipients"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseInput", {
                attrs: {
                  type: "textarea",
                  label: _vm.$t("lang.asunto.msg"),
                  placeholder: _vm.$t("lang.asunto.msg"),
                  error: _vm.errors.first("subject")
                },
                model: {
                  value: _vm.subject,
                  callback: function($$v) {
                    _vm.subject = $$v
                  },
                  expression: "subject"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "mt-4 d-flex justify-content-center align-items-center"
            },
            [
              _c(
                "button",
                {
                  staticClass: "button button--secondary w-100 mr-1",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lang.cancelar_imagen.msg")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "button w-100 ml-1",
                  class: {
                    "button--disabled": _vm.loading || !_vm.formIsValid
                  },
                  attrs: {
                    type: "submit",
                    disabled: _vm.loading || !_vm.formIsValid
                  }
                },
                [
                  _vm.loading
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.$t("lang.enviar.msg")) + " ")
                ],
                1
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <ToggleButton
      :active="active"
      @click="$emit('click')"
      class="d-flex align-items-center justify-content-center"
    >
      <i class="tmlps-icn-calendar d-none d-md-inline"></i>
      <span class="ml-md-3 font-weight-bold">{{ label }}</span>
    </ToggleButton>
    <div class="small text-center" v-if="photo && isToday">{{ $t('lang.hoy.msg') }}</div>
  </div>
</template>

<script>
import ToggleButton from '@/components/ToggleButton';

export default {
  name: 'PhotoDateSelectorButton',
  components: {
    ToggleButton,
  },
  props: {
    active: { type: Boolean, default: false },
    photo: { type: Object, required: false },
  },
  computed: {
    label() {
      if (!this.photo) {
        return 'Sin imágenes';
      }

      const date = new Date(this.photo.captured_at);
      const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
      const timeOptions = { hour: 'numeric', hour12: false, minute: 'numeric' };
      return `${date.toLocaleTimeString(undefined, timeOptions)} - ${date.toLocaleDateString(
        undefined,
        dateOptions,
      )}`;
    },
    isToday() {
      const [nowYear, nowMonth, nowDay] = new Date().toDateString().split('-');
      const [imageYear, imageMonth, imageDay] = new Date(this.photo.captured_at)
        .toDateString()
        .split('-');
      return nowYear === imageYear && nowMonth === imageMonth && nowDay === imageDay;
    },
  },
};
</script>

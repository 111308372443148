var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "ToggleButton",
        {
          staticClass: "d-flex align-items-center justify-content-center",
          attrs: { active: _vm.active },
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        },
        [
          _c("i", { staticClass: "tmlps-icn-calendar d-none d-md-inline" }),
          _c("span", { staticClass: "ml-md-3 font-weight-bold" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        ]
      ),
      _vm.photo && _vm.isToday
        ? _c("div", { staticClass: "small text-center" }, [
            _vm._v(_vm._s(_vm.$t("lang.hoy.msg")))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "tl-tags-input",
        class: { "tl-tags-input--has-error": _vm.error }
      },
      [
        _vm._l(_vm.value, function(tag, index) {
          return _c("span", { key: index, staticClass: "tl-tags-input__tag" }, [
            _c("span", [_vm._v(_vm._s(tag))]),
            _c(
              "button",
              {
                staticClass: "tl-tags-input__remove",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.removeTag(tag)
                  }
                }
              },
              [_c("span", [_vm._v("×")])]
            )
          ])
        }),
        _c("input", {
          staticClass: "tl-tags-input__input",
          attrs: {
            type: "text",
            placeholder: _vm.$t("lang.agrega_correo.msg")
          },
          domProps: { value: _vm.newTag },
          on: {
            input: function($event) {
              _vm.newTag = $event.target.value
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.addTag($event)
            },
            blur: _vm.addTag
          }
        })
      ],
      2
    ),
    _vm.error
      ? _c("p", { staticClass: "error-message" }, [_vm._v(_vm._s(_vm.error))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Drawer",
    {
      attrs: { active: _vm.show },
      on: {
        close: function($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticClass: "photo-selection__title" },
          [
            _vm._t("titledrawer", [
              _vm._v(_vm._s(_vm.$t("lang.elige_fecha.msg")))
            ])
          ],
          2
        )
      ]),
      _c("div", { staticClass: "photo-selection__content" }, [
        _c(
          "div",
          { staticClass: "photo-selection__datepicker" },
          [
            _c("VueCtkDateTimePicker", {
              class: { "ocultar-menu-calendario": _vm.esPublico },
              attrs: {
                inline: "",
                "only-date": "",
                format: "YYYY-MM-DD",
                "disabled-dates": _vm.camera.dates_without_photos,
                "max-date": _vm.maxDate,
                "min-date": _vm.minDate,
                "no-header": "",
                "no-button": "",
                "no-shortcuts": "",
                "no-label": "",
                "no-keyboard": "",
                color: "var(--primary)",
                locale: _vm.$i18n.locale,
                value: _vm.formattedDate
              },
              on: { input: _vm.fetchPhotos }
            })
          ],
          1
        ),
        _c("div", { staticClass: "photo-selection__divider" }),
        _c(
          "div",
          { staticClass: "photo-selection__photos", attrs: { id: "divAbajo" } },
          [
            !_vm.loading && _vm.photos.length === 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center h-100"
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("lang.sin_fotos_fecha.msg")))
                    ])
                  ]
                )
              : _c(
                  "div",
                  {
                    class: {
                      "align-items-center justify-content-center":
                        _vm.photos.length === 0,
                      "photo-selection__photos-wrapper": !_vm.loading
                    }
                  },
                  [
                    _c(
                      "b-overlay",
                      { attrs: { show: _vm.loading, rounded: "sm" } },
                      [
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br"),
                        _c("br")
                      ]
                    ),
                    _vm._l(_vm.photos, function(photo) {
                      return _c(
                        "div",
                        {
                          key: photo.id,
                          staticClass: "photo-selection__photo",
                          class: {
                            "photo-selection__photo--active": _vm.value
                              ? _vm.value.id === photo.id
                              : false
                          },
                          style: _vm.loading ? "visibility: hidden;" : "",
                          on: {
                            click: function($event) {
                              return _vm.handleSelectPhoto(photo)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              loading: "lazy",
                              src: photo.optimized_url,
                              alt: photo.name
                            }
                          }),
                          _c(
                            "p",
                            { staticClass: "photo-selection__photo-time" },
                            [
                              _vm._v(
                                _vm._s(_vm.formatCaptureTime(photo.captured_at))
                              )
                            ]
                          ),
                          _c(
                            "svg",
                            {
                              staticClass: "photo-selection__photo-check",
                              attrs: {
                                fill: "currentColor",
                                viewBox: "0 0 20 20"
                              }
                            },
                            [
                              _c("path", {
                                attrs: {
                                  "fill-rule": "evenodd",
                                  d:
                                    "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z",
                                  "clip-rule": "evenodd"
                                }
                              })
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
          ]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Modal :open="open" @close="$emit('close')" :title="$t('lang.enviar_imagen.msg')">
    <form @submit.prevent="handleSubmit">
      <p>{{ $t('lang.correos.msg') }}</p>

      <div class="mt-3">
        <EmailTagsInput
          :max-items="10"
          v-model="recipients"
          :error="errors.firstOfArray('recipients')"
        />
      </div>

      <div class="mt-4">
        <BaseInput
          type="textarea"
          :label="$t('lang.asunto.msg')"
          :placeholder="$t('lang.asunto.msg')"
          v-model="subject"
          :error="errors.first('subject')"
        />
      </div>

      <div class="mt-4 d-flex justify-content-center align-items-center">
        <button type="button" class="button button--secondary w-100 mr-1" @click="$emit('close')">
          {{ $t('lang.cancelar_imagen.msg') }}
        </button>
        <button
          type="submit"
          class="button w-100 ml-1"
          :disabled="loading || !formIsValid"
          :class="{ 'button--disabled': loading || !formIsValid }"
        >
        <b-spinner v-if="loading" small></b-spinner>
          {{ $t('lang.enviar.msg') }}
        </button>
      </div>
    </form>
  </Modal>
</template>

<script>
import Errors from '@/util/Errors';
import Modal from '@/components/Modal';
import BaseInput from '@/components/BaseInput';
import EmailTagsInput from '@/components/EmailTagsInput';

export default {
  name: 'SendPhotoModal',
  components: { Modal, EmailTagsInput, BaseInput },
  props: {
    open: { type: Boolean, default: false },
    photo: { type: Object },
  },
  data() {
    return {
      recipients: [],
      subject: '',
      loading: false,
      errors: new Errors(),
    };
  },
  computed: {
    formIsValid() {
      return this.photo && this.recipients.length > 0 && this.subject.trim().length > 0;
    },
  },
  methods: {
    async handleSubmit() {
      this.errors.clear();
      this.loading=true;
      try {
        await this.$store.dispatch('photo/sendPhoto', {
          photo: this.photo,
          subject: this.subject,
          recipients: this.recipients,
          idioma: this.$i18n.locale || 'es'
        });
        this.loading=false;
      } catch (error) {
        this.loading=false;
        this.errors.capture(error.response.data.errors);
        return;
      }

      this.subject = '';
      this.recipients = [];
      this.$emit('close');
      this.notifySuccess();
    },

    notifySuccess() {
      const notification = {
        type: 'success',
        message: this.$t('lang.imagen_enviada.msg'),
      };
      this.$store.dispatch('notification/add', notification, { root: true });
    },
  },
};
</script>

<style lang="sass" scoped>
.button
  background-color: var(--primary)
  padding: 0.75rem 3.5rem
  border-radius: 0.25rem
  color: white
  font-size: 0.875rem

  &--secondary
    background-color: rgba(74,98,212,0.2)
    padding: 0.75rem 3.5rem
    border-radius: 0.25rem
    color: #242424
    font-size: 0.875rem

  &--disabled
    background-color: #E5E7EB
    color: gray
    cursor: not-allowed
</style>

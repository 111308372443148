<template>
  <div>
    <div class="tl-tags-input" :class="{ 'tl-tags-input--has-error': error }">
      <span class="tl-tags-input__tag" v-for="(tag, index) in value" :key="index">
        <span>{{ tag }}</span>
        <button type="button" class="tl-tags-input__remove" @click="removeTag(tag)">
          <span>&times;</span>
        </button>
      </span>
      <input
        class="tl-tags-input__input"
        type="text"
        :value="newTag"
        :placeholder="$t('lang.agrega_correo.msg')"
        @input="newTag = $event.target.value"
        @keydown.enter.prevent="addTag"
        @blur="addTag"
      />
    </div>

    <p class="error-message" v-if="error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'EmailTagsInput',
  props: {
    value: { type: Array, default: () => [] },
    maxItems: { type: Number, default: 5 },
    error: { type: String, default: '' },
  },
  data() {
    return {
      newTag: '',
    };
  },

  methods: {
    addTag() {
      if (this.value.length >= this.maxItems) {
        return;
      }

      if (this.newTag.trim().length === 0) {
        return;
      }

      if (this.value.includes(this.newTag.trim())) {
        return;
      }

      this.$emit('input', [...this.value, this.newTag.trim()]);
      this.newTag = '';
    },
    removeTag(tag) {
      this.$emit(
        'input',
        this.value.filter((t) => t !== tag),
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.tl-tags-input
  $self: &
  display: flex
  flex-wrap: wrap
  background-color: #fff
  border: 1px solid #C1C1C1
  border-radius: .25rem
  transition-property: border-color
  transition-duration: 0.2s
  transition-timing-function: linear
  overflow: hidden
  padding-top: .5rem
  padding-right: 1rem
  padding-bottom: .25rem
  padding-left: .5rem
  &--has-error
    border-color: red

  &__tag
    display: inline-flex
    align-items: center
    border-radius: .25rem
    background-color: rgba(74,98,212,0.1)
    padding: .5rem
    user-select: none
    margin-right: .5rem
    margin-bottom: .25rem
    font-size: 0.875rem
    font-weight: 300

    &:last-of-type
      margin-right: 0

  &__input
    border: none
    background: transparent
    outline: 0
    font-size: 0.875rem
    padding-top: .25rem
    padding-bottom: .25rem
    margin-left: .5rem
    margin-bottom: .25rem
    flex: 1

  &__remove
    display: flex
    align-items: end
    justify-content: center
    text-align: center
    outline: 0
    line-height: 1.25
    background-color: white
    border-radius: 9999px
    height: 1.25rem
    width: 1.25rem
    font-size: 1.25rem
    color: #242424
    &:first-child
      margin-right: .25rem
    &:last-child
      margin-left: .5rem

.error-message
  color: red
  font-size: 0.75rem
  padding-top: .125rem
  padding-right: 1rem
</style>

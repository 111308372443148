var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-flex flex-column align-items-center" },
    [
      _c(
        "button",
        {
          staticClass: "btn-circle btn-line mb-1",
          on: {
            click: function($event) {
              return _vm.$emit("click")
            }
          }
        },
        [_c("i", { staticClass: "tmlps-icn-mail h3 m-0" })]
      ),
      _c("div", { staticClass: "small text-center" }, [
        _vm._v(_vm._s(_vm.$t("lang.send.msg")))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }